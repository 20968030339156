<template>
  <div class="products">
        <div class="products_head">
          <div class="products_tit_box">
                <div class="products_tit">特色</div>
                <div class="products_shu"></div>
          </div>
          <img src="../assets/img/products_bg1.png" alt="">
        </div>
        <div class="products-content-box">
            <div class="products_content">
              <div class="products_tit">重要场景</div>
              <div class="products_shu"></div>
              <div class="products_content_box">
                <div class="products_content_box_left">
                     <div :class="['left-item',{'left-item-active':active==index}]"  v-for="(item,index) in itemDate" :key="index" @click="changeImg(index)">
                          <p class="item_p1">{{item.name}}</p>
                          <p class="item_p2"><span>痛点：</span>{{item.pain}}</p>
                          <p class="item_p3"><span>简介：</span>{{item.deatil}}</p>
                     </div>
                </div>
                <div class="products_content_box_right">
                      <el-carousel  type="card" :autoplay="false" indicator-position='none' arrow="never">
                        <el-carousel-item v-for="item in imgUrlArr" :key="item">
                          <div class="swiper_item">
                            <viewer>
                              <img :src="item" alt="">
                            </viewer>
                          </div>
                        </el-carousel-item>
                      </el-carousel>
                </div>
              </div>
        </div>
        </div>
        <div style="height:100px;background:#fff;"></div>
  </div>
</template>

<script>
import Vue from 'vue'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer,{ defaultOptions:{
        zIndex: 9999,
　　　　　toolbar: false
}});
export default {
  name: 'products',
  data() {
      return {
         imgdate:[[require('../assets/scene/1/1.png')],[require('../assets/scene/2/1.png'),require('../assets/scene/2/2.png'),require('../assets/scene/2/3.png')]
         ,[require('../assets/scene/3/1.png'),require('../assets/scene/3/2.png'),require('../assets/scene/3/3.png')],[require('../assets/scene/4/1.png'),require('../assets/scene/4/2.png'),require('../assets/scene/4/3.png')],
         [require('../assets/scene/5/1.png'),require('../assets/scene/5/2.png'),require('../assets/scene/5/3.png')],[require('../assets/scene/6/1.png'),require('../assets/scene/6/2.png'),require('../assets/scene/6/3.png')],
         [require('../assets/scene/7/1.png'),require('../assets/scene/7/2.png'),require('../assets/scene/7/3.png')],[require('../assets/scene/8/1.png'),require('../assets/scene/8/2.png'),require('../assets/scene/8/3.png')],
         [require('../assets/scene/9/1.png'),require('../assets/scene/9/2.png'),require('../assets/scene/9/3.png')]],
         imgUrlArr:[require('../assets/scene/1/1.png')],
         itemDate:[{name:'成长资源池',pain:'补充学校资源体系上的不足',deatil:'职业发展、社会实践培训、创新创业培训'},{name:'第二课堂场景',pain:'生成最炫酷的第二课堂成绩单',deatil:'学分兑换、上传认证、“五育”课程体系导航、提交证明、证书打印'},
         {name:'能力画像场景',pain:'聚焦五育，重新定义学校素质育人',deatil:'汇总能力素质成绩单、高校数据后台'}, {name:'评奖推优场景',pain:'按流程定制，适配高校个性化推优',deatil:'资料上传、资格审核、逐级审批、获奖公示'},
         {name:'社会实践场景',pain:'项目管理全过程督导、可知可控',deatil:'社会实践报名、组队、审核、过程监控、结果上传'},{name:'社团管理场景',pain:'面向社团全生命周期实现一站式管理',deatil:'社团招新、申请审核、年审、场地及活动审批'},
         {name:'团组织管理场景',pain:'最完善的基础团务工作',deatil:'三会两制一课、基础团务'},{name:'文体活动场景',pain:'最便捷的学生报名、签到系统',deatil:'校园活动发布、学生报名签到、批量提交、教师打分评价'},
         {name:'习惯养成、打卡插件',pain:'最励志的高校朋友圈',deatil:'读书打卡、光盘行动、规律学习'}
         ],
         active:0,
      };
    },
    methods: {
      changeImg(val){
         this.imgUrlArr=this.imgdate[val];
         this.active=val;
      }
    }
}
</script>
<style lang="scss" scoped>
.products{
  background: #fff;
}
.products_head{
  position: relative;
  img{
    width: 100%;
  }
}
.products-content-box{
  background: url('../assets/img/products_bg2.png') no-repeat;
  width: 100%;
}
.products_content{
  width: 1200px;
  background-size: cover;
  height: 692px;
  margin: 0 auto;
  padding: 0 80px;
}
.products_tit_box{
  position: absolute;
  top: 47px;
  left: 50%;
  transform: translate(-50%, -50%);
  .products_tit{
  font-size: 36px;
  color: #fff;
  font-weight: bold;
  margin-top: 57px;
}
  .products_shu{
    width: 4px;
    height: 36px;
    background: #FFF;
    margin: 0 auto;
    margin-top: 9.5px;
  }
}
.products_content{
  margin-top: 104px;
    .products_tit{
        font-size: 36px;
        color: #20A8D9;
        font-weight: bold;
    }
     .products_shu{
    width: 4px;
    height: 36px;
    background: #20A8D9;
    margin: 0 auto;
    margin-top: 9.5px;
    margin-bottom: 84.5px;
  }
}
.products_content_box{
  display: flex;
  align-items: center;
  .products_content_box_left{
    width: 440px;
    height: 470px;
    background: #20A8D9;
    border-radius: 15px;
    overflow-y: auto;
    padding: 20px;
    padding-bottom: 10px;
    .left-item{
      background: rgba(255,255,255,.2);
      border-radius: 10px;
      // height: 120px;
      color: #fff;
      padding: 15px 20px;
      text-align: left;
      
      cursor: pointer;
      margin-bottom: 9px;
      .item_p1{
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
      }
      .item_p2{
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;
        // display: flex;
        // align-items: center;
      }
      .item_p3{
          font-size: 14px;
      }
      span{
        font-size: 14px;
        color: #fff;
      }
    }
  }
}
.products_content_box_left::-webkit-scrollbar {
  width: 25px;
}
// <!--修改 滚动条的 下面 的 样式-->
.products_content_box_left::-webkit-scrollbar-track {
  background-color: #20A8D9;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
// <!--修改 滑块 -->
.products_content_box_left::-webkit-scrollbar-thumb {
  background-color: rgba(255,255,255,.5);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
.swiper_item{
  background: url('../assets/img/phone.png') no-repeat;
  background-size: contain;
  height: 498px;
  border-radius: 28px;
  padding: 44.5px 38px;
  // box-shadow: 0 0 20px rgba(0,0,0,.1);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  div{
     height:calc(100% - 40px)  !important;
     width: 100%;
     overflow: hidden;
  img{
    width: 100%;
  }
  }
}
.products_content_box_right{
   height: 498px;
   width: 600px;
  margin-left: 95px;
   ::v-deep .el-carousel{
    height: 498px !important;
    div{
      height: 498px;
    }
}}
.left-item-active{
  background: #FFFFFF !important;
  color: #7C829D  !important;
  .item_p1{
        color: #2A2E3D  !important;
      }
      .item_p2{
        color: #FE943F !important;
      }
      span{
        color: #7C829D !important;
      }
}
</style>
